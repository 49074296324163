import {
  Component,
  Input,
  Output,
  EventEmitter,
  Signal,
  computed,
  ChangeDetectionStrategy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { Event } from '@models/events/event.model';
import { EventTemplateVisual } from '@models/design-templates/event-template-visual.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserAvatarComponent } from '@modules/shared/components/user-avatar/user-avatar.component';
import { AppRoutes } from 'app/routes';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from '@services/shared/notification.service';
import { EventService } from '@services/events/event.service';
import { MatDialog } from '@angular/material/dialog';
import { YesNoDialog } from '@modules/customer/dialogs/yes-no/yes-no.dialog';
import moment from 'moment-timezone';

@Component({
  selector: 'app-event-card',
  standalone: true,
  imports: [CommonModule, TranslateModule, UserAvatarComponent],
  providers: [NotificationService],
  templateUrl: './event-card.component.html',
  styleUrl: './event-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventCardComponent {
  @Input() event!: Event;

  @Input() loggedUserId?: number;

  @Input() showActionBar = true;

  @Input() fromLinkBio = false;

  @Output() refresh = new EventEmitter<boolean>();
  userLang: string = 'en';

  encodedMainImage: Signal<string> = computed(() => {
    if (
      this.event.isGeneric() &&
      this.event.eventTemplate?.seo?.sharingPictureUrl
    ) {
      return this.event.eventTemplate?.seo?.sharingPictureUrl;
    }
    return this.event ? this.event.getEncodedMainImage() : '';
  });

  constructor(
    private eventService: EventService,
    private router: Router,
    private clipboard: Clipboard,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private dialog: MatDialog,
  ) {
    let language = localStorage.getItem('userLang');
    this.userLang = language ? language : 'en';
  }

  get visual(): EventTemplateVisual | undefined {
    return this.event?.eventTemplate?.visual;
  }

  goToEvent() {
    if (this.fromLinkBio) {
      this.router.navigate(['/', AppRoutes.Events.events, this.event.uri], {
        queryParams: { fromLinkBio: true },
      });
    } else {
      this.router.navigate(['/', AppRoutes.Events.events, this.event.uri]);
    }
  }
  getEventDateInfoForCard(): string {
    const currentLang = this.translateService.currentLang;

    // Set Moment.js locale based on current language
    if (currentLang === 'de') {
      moment.locale('de');
    } else {
      moment.locale('en-gb'); // Or whatever your default locale is
    }

    if (!this.event.endDate) {
      if (!this.event.startDate) {
        return 'TBD';
      }
      // Format based on locale
      return currentLang === 'de'
        ? moment(this.event.startDate).format('DD[.] MMMM')
        : moment(this.event.startDate).format('Do [of] MMMM · ha');
    }

    // Format based on locale
    const startDate =
      currentLang === 'de'
        ? moment(this.event.startDate).format('DD[.] MMMM')
        : moment(this.event.startDate).format('Do [of] MMMM');

    const startTime = moment(this.event.startDate).format('ha');
    const endTime = moment(this.event.endDate).format('ha');

    if (this.event.endsOnSameDate()) {
      return `${startDate} · ${startTime} - ${endTime}`;
    }

    // Format based on locale
    return currentLang === 'de'
      ? moment(this.event.startDate).format('DD[.] MMMM')
      : moment(this.event.startDate).format('Do [of] MMMM · ha');
  }

  onEdit(e: any) {
    e.stopPropagation();
    this.router.navigate(['/', AppRoutes.Events.events, this.event.uri], {
      queryParams: { edit: true },
    });
  }

  onLink(e: any) {
    e.stopPropagation();
    this.clipboard.copy(this.event.getLink());

    this.notificationService.success(
      this.translateService.instant(
        'APP.AFFILIATE_PRODUCTS_PAGE_LIST.SUCCESS.LINK_COPIED',
      ),
    );
  }

  onDuplicate(e: any) {
    e.stopPropagation();

    const dialogRef = this.dialog.open(YesNoDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        title: 'APP.EVENT_EDIT.DUPLICATE_EVENT',
        message: 'APP.EVENT_EDIT.DUPLICATE_EVENT_MESSAGE',
      },
      panelClass: ['normal-dialog'],
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.eventService.duplicate(this.event.id).subscribe({
          next: (uri) => {
            // this.refresh.emit(true);
            this.notificationService.success(
              this.translateService.instant(
                'APP.EVENT_EDIT.SUCCESS.DUPLICATE_SUCCESS',
              ),
            );
            this.router.navigate(['/', AppRoutes.Events.events, uri]);
          },
          error: () => {
            this.notificationService.error(
              this.translateService.instant(
                'APP.EVENT_EDIT.ERRORS.DUPLICATE_ERROR',
              ),
            );
          },
        });
      }
    });
  }
}
